<template>
  <div class="detail-page">
    <Header />
    <Card class="card">
      <div class="label-list-container" >
        <div class="label-value-container">
          <div class="label">发票类型：</div>
          <div class="value">{{invoiceInfo.invoiceType || '无'}}</div>
        </div>
        <div class="label-value-container">
          <div class="label">名称：</div>
          <div class="value">{{invoiceInfo.name || '无'}}</div>
        </div>
        <div class="label-value-container">
          <div class="label">纳税人识别号：</div>
          <div class="value">{{invoiceInfo.taxpayerNumber || '无'}}</div>
        </div>
        <div class="label-value-container">
          <div class="label">地址：</div>
          <div class="value">{{invoiceInfo.address || '无'}}</div>
        </div>
        <div class="label-value-container">
          <div class="label">电话：</div>
          <div class="value">{{invoiceInfo.phone || '无'}}</div>
        </div>
        <div class="label-value-container">
          <div class="label">开户行：</div>
          <div class="value">{{invoiceInfo.bankName || '无'}}</div>
        </div>
        <div class="label-value-container">
          <div class="label">账号：</div>
          <div class="value">{{invoiceInfo.bankNumber || '无'}}</div>
        </div>
      </div>
    </Card>
  </div>
</template>

<script>
import Header from '@/components/header/index.vue'
import Card from '@/components/common/card/whiteCard.vue'
import {getDealerInvoiceInfo} from "@/api/home";


export default {
  components: {
    Header,
    Card,
  },
  data() {
    return {
      invoiceInfo: {},
    }
  },
  activated() {
    this.getInvoiceInfo();
  },
  methods: {
    async getInvoiceInfo() {
      const res = await getDealerInvoiceInfo();
      this.invoiceInfo = res.data;
    },
  },
}
</script>

<style lang="scss" scoped>
.detail-page {
  width: 100%;
  min-height: 100vh;
  padding-bottom: 20px;
  .card {
    margin-top: 10px;
    .label-list-container {
      padding-top: 10px;
      padding-bottom: 12px;
    }
    .label-value-container {
      width: 100%;
      height: auto;
      display:inline-block;
      align-items: center;
      font-size: 14px;
      &:not(:last-child){
        margin-bottom: 6px;
      }
      .label {
        float: left;
        color: #818181;
      }
      .value {
        color: #2C2C2C;
        //float: right;
        text-align: right;
        //margin-left: 6px;
      }
    }
  }

}
</style>

